import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from './pages/adminpages/Dashboard';
import SurveyRequest from "./pages/adminpages/SurveyRequest"
import SurveyRequstAction from "./pages/adminpages/SurveyRequestAction"
import Bids from './pages/adminpages/Bids';
import GroupBuyer from './pages/adminpages/buyer/Group';
import Indivisual from './pages/adminpages/buyer/Indivisual';
// import VarifyRequest from './pages/adminpages/buyer/VarifyRequest';
import SettingPage from './pages/adminpages/Setting';
import ProfilePage from "./pages/adminpages/Profile"
import Manager from "./pages/adminpages/Manager"
import Survey from "./pages/adminpages/surveys/Survey"
import Surveyors from "./pages/adminpages/surveys/Surveyors"
import VarifyRequest from "./pages/adminpages/buyer/VarifyRequest"
import GroupList from "./pages/adminpages/buyer/GroupList"
import LoginProtect from './auth/LoginRouteProtect';
import LoginPage from './pages/Auth/LoginPage';
import AdminRouteProtect from './auth/AdminRouteProtect';
import SurveyorLoginPage from './pages/Auth/SurveyorLoginPage';
import SurveyorRouteProtect from './auth/SurveyorRouthProtect';
import SurveyorDashboardPage from './pages/surveyorPages/SurveyorDashboardPage';
import SurveyorSurvey from './pages/surveyorPages/SurveyorSurvey';
import AssignedSurveyPage from './pages/surveyorPages/AssignedSurveyPage';
import SurveySettingsPage from './pages/surveyorPages/SurveyorSettingsPage';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<LoginProtect />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/surveyorLogin" element={<SurveyorLoginPage />} />
          </Route>
          <Route element={<AdminRouteProtect />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/bids" element={<Bids />} />
            <Route path="/surveyrequest" element={<SurveyRequest />} />
            <Route path="/surveyrequstaction" element={<SurveyRequstAction />} />
            <Route path="/buyer/GroupBuyer" element={<GroupBuyer />} />
            <Route path="/buyer/Indivisual" element={<Indivisual />} />
            <Route path="/buyer/GroupBuyer/GroupList" element={<GroupList />} />
            {/* <Route path="/buyer/VarifyRequest" element={<VarifyRequest />} /> */}
            <Route path="/setting" element={<SettingPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/managers" element={<Manager />} />
            <Route path="/varifyRequest" element={<VarifyRequest />} />
            <Route path="/surveys/list" element={<Survey />} />
            <Route path="/surveys/surveyors" element={<Surveyors />} />
          </Route>
          <Route element={<SurveyorRouteProtect />}>
            <Route path="/surveyor" element={<SurveyorDashboardPage />} />
            <Route path="/mysurveys" element={<SurveyorSurvey />} />
            <Route path="/assignedsurvey" element={<AssignedSurveyPage />} />
            <Route path="/settings" element={<SurveySettingsPage />} />
          </Route>





        </Routes>
      </Router>
    </div>
  );
}

export default App;
