import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Tooltip, Tag, message, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from "react-icons/go";

import Userimg from "../../../img/userimg.svg"
import { IoMdDownload } from "react-icons/io";

import { Link } from 'react-router-dom';
import CreateSurvey from './CreateSurvey';
import ErrorLogout from '../../../helpers/ErrorLogout';
import { getAllMySurveys } from '../../../api/SurveyorApiRequest';
import Pagination from '../../common/Pagination';
import * as XLSX from 'xlsx';

const MySurvey = () => {
    const [activeTab, setActiveTab] = useState('');

    const [CreateSurveyShow, setCreateSurveyShow] = useState(false);

    const [loader, setLoader] = useState(false)

    const [totalPages, setTotalPages] = useState(5);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [trigger, setTrigger] = useState(false);

    const [mySurvey, setMySurvey] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [tabFilter, setTabFilter] = useState(''); // Update initial state to 'all'

    const tabs = [
        { label: 'All', value: '' },
        { label: 'Approved', value: 'active' },
        { label: 'Pending', value: 'inactive' },
        { label: 'Rejected', value: 'deactive' },
    ];

    const handleSelectUser = (user) => {
        setSelectedUsers(prevSelectedUsers => {
            const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
            if (isSelected) {
                return prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id);
            } else {
                return [...prevSelectedUsers, user];
            }
        });
    };


    useEffect(() => {
        const viewAllMySurveys = async () => {
            try {
                setLoader(true);
                const { data } = await getAllMySurveys({
                    // q: searchUser,
                    page: currentPage,
                    limit: limit,
                    status: tabFilter

                });
                if (data?.success) {
                    setMySurvey(data?.surveys);
                    setTotalPages(data?.totalPages);
                    setCurrentPage(data?.currentPage);
                    setTotalItems(data?.totalItems);
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.log(error);
                console.log(error.message);
                if (error?.response?.status === 401) {
                    ErrorLogout(error);
                }
            }
        };
        viewAllMySurveys();
    }, [limit, currentPage, trigger, tabFilter]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleLimit = (e) => {
        const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
        setLimit(selectedValue); // Update state with the selected value
        setCurrentPage(1);
    }

    const handleCancelSelection = () => {
        setSelectedUsers([]);
        message.info('Selection cancelled.');
    };


    // ----------------- DOWNLOAD----------
    const handleDownloadData = () => {

        if (selectedUsers.length === 0) {
            message.warning('Please select at least one item to download.');
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(selectedUsers.map(item => {
            // Create an object to store all specification key-value pairs
            const specObj = {};
            item.productSpecification.forEach(spec => {
                specObj[spec.name] = spec.value;
            });

            return {
                'ID': item._id,
                'Client Name': item.surveyID.clientName,
                'Product Name': item.productName,
                'Category': item.category.name,
                'Description': item.productDescription,
                'Key Specification': item.keySpec,
                'Expected Price': item.expectedPrice,
                'Bid Status': item.bidStatus,
                'Current Bid Price': item.bidLivePrice,
                'Initial Bid Price': item.bidInitialPrice,
                'Created Date': new Date(item.createdAt).toLocaleDateString('en-GB'), // DD/MM/YYYY format
                ...specObj // Spread the specification object to create individual columns
            };
        }));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

        XLSX.writeFile(workbook, "selected_products.xlsx");

        message.success(`Data for ${selectedUsers.length} product(s) downloaded successfully.`);
    };


    const columns = [

        {
            title: 'Select',
            dataIndex: 'select',
            width: 60,
            render: (_, record) => (
                <Checkbox
                    checked={selectedUsers.some(user => user._id === record._id)}
                    onChange={() => handleSelectUser(record)}
                />
            ),
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Client Name
                    <SearchOutlined className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: ['surveyID', 'clientName'],
            key: 'clientName',
            render: (clientName, record) => (
                <div className="flex items-center cursor-pointer">
                    <img src={Userimg} alt="Client avatar" className="w-8 h-8 rounded-full mr-2" />
                    <span className="text-[#313131] text-[13px] font-medium">{clientName || 'N/A'}</span>
                </div>
            ),
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Product Title
                </div>
            ),
            dataIndex: 'productDescription',
            key: 'productDescription',
            render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Category
                    <SearchOutlined className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: ['category', 'name'],
            key: 'category',
            render: (categoryName) => (
                <span className="text-[#313131] text-[13px] font-medium">
                    {categoryName || 'N/A'}
                </span>
            ),
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Expected price
                    <LuArrowUpDown className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: 'expectedPrice',
            key: 'expectedPrice',
            render: text => <span className="text-[#313131] text-[13px] font-medium">{text}</span>,
        },

        {
            title: (
                <div className="flex items-center gap-2">
                    Status
                    <LuArrowUpDown className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <Tag color={
                    status === 'pending' ? 'orange' :
                        status === 'active' ? 'green' :
                            status === 'rejected' ? 'red' : 'default'
                }>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                </Tag>
            ),
        },
        {
            title: 'Bids',
            key: 'actions',
            render: (_, record) => (
                <div className="flex space-x-2">
                    <Tooltip title={
                        record.status === 'pending' ? 'Remind' :
                            record.status === 'active' ? 'View Details' :
                                record.status === 'rejected' ? 'Resubmit' : 'View Details'
                    }>
                        <Button
                            type={record.status === 'rejected' ? 'primary' : 'default'}
                            className={`
                                ${record.status === 'pending' ? 'bg-orange-500 text-white' :
                                    record.status === 'active' ? 'bg-green-500 text-white' :
                                        'text-white'} 
                                hover:border-[#0445C3]
                            `}
                        >
                            {record.status === 'pending' ? 'Remind' :
                                record.status === 'active' ? 'View Details' :
                                    record.status === 'rejected' ? 'Resubmit' : 'View Details'}
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
    ];



    const handleCreate = () => {
        setCreateSurveyShow(true)
    };

    if (CreateSurveyShow) {
        return <CreateSurvey />;
    }

    return (
        <>
            <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
                <div className="mb-4  flex items-center text-sm md:text-base flex-wrap">
                    <Link to="/">
                        <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" />
                    </Link>
                    <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
                        / My Surveys
                    </span>
                    <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
                        {/* <IoMdDownload className='text-[#1955EB] mt-1 text-[20px]' />
                        <p className='text-[#1955EB] underline text-[20px] font-medium'>Download Data</p> */}
                        {selectedUsers.length > 0 && (
                            <>
                                <button
                                    icon={<IoMdDownload className='text-[#1955EB] text-[20px]' />}
                                    onClick={handleDownloadData}
                                    className='text-[#1955EB] underline text-[20px] font-small'
                                >
                                    Download Selected ({selectedUsers.length})
                                </button>
                                <Button
                                    onClick={handleCancelSelection}
                                    className='text-[#FF4545] underline text-[20px] font-medium'
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex flex-wrap justify-between items-center mb-4 gap-4">
                    <div className="flex flex-wrap gap-2 border border-[#9FACF0] p-2 rounded-[8px]">
                        {/* {tabs.map((tab) => (
                            <button
                                key={tab}
                                className={`px-4 py-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
              ${activeTab === tab
                                        ? "bg-[#1955EB] text-white font-medium"
                                        : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))} */}
                        {tabs.map((tab) => (
                            <button
                                key={tab.value}
                                className={`px-4 py-2 mb-2 md:mb-0 md:mr-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                 ${activeTab === tab.value
                                        ? "bg-[#1955EB] text-white font-medium"
                                        : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                                onClick={() => {
                                    setActiveTab(tab.value)
                                    setTabFilter(tab.value)
                                    setCurrentPage(1);

                                }}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                    <div className="flex flex-wrap gap-2">
                        <button
                            onClick={handleCreate}
                            className="px-5 py-2 text-[16px] font-medium text-white bg-[#1955EB] hover:border-[#1955EB] border-[1px] rounded-md hover:bg-white hover:text-[#1955EB]">
                            + Add New Survey
                        </button>
                    </div>
                </div>
            </div>
            <div className="pt-[0px] space-y-10">

<Spin spinning={loader}>

                <Table
                    columns={columns}
                    dataSource={mySurvey} // Use the mySurvey state here
                    pagination={false}
                    className="custom-table-header lg:w-full w-[250px] overflow-x-auto custom-pagination"
                />
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        limit={limit}
                        totalItem={totalItems}
                        onPageChange={handlePageChange}
                        handleLimit={handleLimit}

                    />
                </div>
</Spin>


            </div>
        </>
    );
};

export default MySurvey;
