import axios from './Axios'
import { axiosPrivate } from "./AxiosPrivate";

// --------- ADMIN --------- 
export const getAllSurveyor = (paramsData) => axios.get('/surveyor/getAllSurveyor',{params:paramsData})
export const updateSurveyorStatus = (id, status) => axiosPrivate.put(`/surveyor/updateSurveyorStatus/${id}`, {status})
export const getSingleSurveyor = (bidID) => axiosPrivate.get(`/surveyor/getSingleSurveyor/${bidID}`)
export const createSurveyor = (formData) => axiosPrivate.post(`/surveyor/createSurveyor`, formData)
export const updateSurveyor = (id, formData) => axiosPrivate.put(`/surveyor/updateSurveyor/${id}`, formData)
 export const getSurveyorNames = () => axiosPrivate.get(`/surveyor/getSurveyorNames`)


// ------ SURVEYOR------

export const surveyorLogin = (loginData) => axios.post('/surveyor/login',loginData)
export const surveyorLogout = (refToken) => axios.post('/surveyor/logout',{refToken})


export const getAllMySurveys = (paramsData) => axiosPrivate.get('/surveyor/getAllMySurveys',{params:paramsData})
export const getAllAssignedSurveys = (paramsData) => axiosPrivate.get('/surveyor/getAllMySurveyList',{params:paramsData})
