import React, { useEffect, useState } from 'react';
import { GoHome } from "react-icons/go";
import { PiSignOutBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import useAuthStore from "../../../store/authStore";
import { message, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { adminAccess, getAdminProfile, getNotificationPreference, removeAdminAccess, updateNotificationPreferences, viewAccessManagers } from '../../../api/SettingRequestApi';
import ChangePasswordModal from './ChangePasswordModal';
const { confirm } = Modal;
const SettingsPage = () => {
  const { adminRefToken, logout } = useAuthStore();
  const tabs = ["Account Settings", "Notification & Preferences", "Admin Permissions"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [email, setEmail] = useState("");
  const [adminProfile, setAdminProfile] = useState([]);
  const [manager, setManager] = useState([]);
  const [trigger, setTrigger] = useState(false)
  const [accountSettingLoader, setaccountSettingLoader] = useState(false)
  // const [notificationLoader, setnotificationLoader] = useState(false)
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [adminPermissionLoader, setadminPermissionLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // ------------LOGOUT FUNCTION-----------
  const handleLogout = () => {
    confirm({
      title: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will be redirected to the login page.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        logout(adminRefToken, "admin");
      },
      onCancel() {
        console.log('Logout cancelled');
      },
    });
  };

  // -------------GET ADMIN PROFILE----------
  useEffect(() => {
    const getProfile = async () => {
      try {
        setaccountSettingLoader(true)
        const { data } = await getAdminProfile();


        if (data.success) {
          setAdminProfile(data.admin);
        } else {
          console.error("Failed to fetch admin profile");
        }
      } catch (error) {
        console.log(error);

      } finally {
        setaccountSettingLoader(false)
      }

    };
    getProfile()
  }, [])


  // ------ GET ACCESS MANAGER----------
  useEffect(() => {
    const getAccessManager = async () => {
      try {
        setadminPermissionLoader(true)
        // TODO: Implement logic to fetch admin profile
        const { data } = await viewAccessManagers();

        if (data.success) {
          setManager(data.managers);
        } else {
          console.error("Failed to fetch admin profile");
        }
      } catch (error) {
        console.log(error);

      } finally {
        setadminPermissionLoader(false)
      }

    };
    getAccessManager()
  }, [trigger])

  // ----------- Give Access to Manager------
  const handleAddEmail = async () => {
    // Basic email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      message.error('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    try {
      const response = await adminAccess(email);


      if (response.data.success) {
        message.success('Admin access granted successfully');
        setEmail(""); // Clear the input field
        setTrigger(!trigger)
        // Refresh the list of managers

      } else {
        message.error(response.data.message || 'Failed to grant admin access');
      }
    } catch (error) {
      console.error("Error granting admin access:", error);
      message.error('An error occurred while granting admin access');
    } finally {
      setIsLoading(false);
    }
  };

  // --------------- REMOVE ACCESS TO MANAGER--------
  const handleRemoveAccess = async (id) => {
    confirm({
      title: 'Are you sure you want to remove access?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will remove access for this manager.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {

        try {
          const response = await removeAdminAccess(id);
          if (response.data.success) {
            message.success('Access removed successfully');
            setTrigger(!trigger)

          } else {
            throw new Error(response.data.message || 'Failed to remove access');
          }
        } catch (error) {
          console.error("Error removing access:", error);
          message.error(error.message || 'An error occurred while removing access');
        } finally {

        }
      },
      onCancel() {
        message.info('Remove access cancelled');
      },
    });
  };


  // ------------------ CHANGE PASSWORD------------
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);

  const showChangePasswordModal = () => {
    setIsChangePasswordModalVisible(true);
  };

  const handleChangePasswordSuccess = () => {
    setIsChangePasswordModalVisible(false);
    // You might want to refresh some data or update the UI here
  };

  // --------------- NOTIFICATION----------------

  // const [preferences, setPreferences] = useState({
  //   newSurveyRequest: false,
  //   assignedSurveysNotAccepted: false,
  //   surveysNotCompleted: false,
  //   newVerificationRequest: false,
  // });

  // useEffect(() => {
  //   fetchPreferences();
  // }, []);

  // const fetchPreferences = async () => {
  //   setNotificationLoader(true);
  //   try {
  //     const { data } = await getNotificationPreference();
  //     if (data.success && data.preferences) {
  //       const { newSurveyRequest, assignedSurveysNotAccepted, surveysNotCompleted, newVerificationRequest } = data.preferences;
  //       setPreferences({
  //         newSurveyRequest,
  //         assignedSurveysNotAccepted,
  //         surveysNotCompleted,
  //         newVerificationRequest,
  //       });
  //     } else {
  //       throw new Error('Preferences data not found in the response');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching preferences:', error);
  //     message.error('Failed to load notification preferences');
  //   } finally {
  //     setNotificationLoader(false);
  //   }
  // };

  // const handlePreferenceChange = (event) => {
  //   const { name, checked } = event.target;
  //   setPreferences(prev => ({ ...prev, [name]: checked }));
  // };

  // const savePreferences = async () => {

  //   setNotificationLoader(true);
  //   try {
  //     await updateNotificationPreferences(preferences);
  //     message.success('Notification preferences updated successfully');
  //   } catch (error) {
  //     console.error('Error updating preferences:', error);
  //     message.error('Failed to update notification preferences');
  //   } finally {
  //     setNotificationLoader(false);
  //   }
  // };
  // const [notificationLoader, setNotificationLoader] = useState(false);
  const [preferences, setPreferences] = useState({
    newSurveyRequest: false,
    assignedSurveysNotAccepted: false,
    surveysNotCompleted: false,
    newVerificationRequest: false,
  });
  const [initialPreferences, setInitialPreferences] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    fetchPreferences();
  }, []);

  useEffect(() => {
    // Check if current preferences differ from initial preferences
    const changed = Object.keys(preferences).some(key => preferences[key] !== initialPreferences[key]);
    setHasChanges(changed);
  }, [preferences, initialPreferences]);

  const fetchPreferences = async () => {
    setNotificationLoader(true);
    try {
      const { data } = await getNotificationPreference();
      if (data.success && data.preferences) {
        const { newSurveyRequest, assignedSurveysNotAccepted, surveysNotCompleted, newVerificationRequest } = data.preferences;
        const fetchedPreferences = {
          newSurveyRequest,
          assignedSurveysNotAccepted,
          surveysNotCompleted,
          newVerificationRequest,
        };
        setPreferences(fetchedPreferences);
        setInitialPreferences(fetchedPreferences);
      } else {
        throw new Error('Preferences data not found in the response');
      }
    } catch (error) {
      console.error('Error fetching preferences:', error);
      message.error('Failed to load notification preferences');
    } finally {
      setNotificationLoader(false);
    }
  };

  const handlePreferenceChange = (event) => {
    const { name, checked } = event.target;
    setPreferences(prev => ({ ...prev, [name]: checked }));
  };

  const savePreferences = async () => {
    setNotificationLoader(true);
    try {
      const { data } = await updateNotificationPreferences(preferences);
      if (data.success) {
        message.success('Notification preferences updated successfully');
        setInitialPreferences(preferences); // Update initial preferences after successful save
        setHasChanges(false); // Reset changes flag
      } else {
        throw new Error('Failed to update preferences');
      }
    } catch (error) {
      console.error('Error updating preferences:', error);
      message.error('Failed to update notification preferences');
    } finally {
      setNotificationLoader(false);
    }
  };




  return (
    <div className="">
      <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
        <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
          <Link to="/">  <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
          <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
            / Settings
          </span>
        </div>
        <div className="flex flex-wrap justify-between items-center mb-4 gap-2">
          <div className="flex flex-wrap gap-2 border border-gray-300 p-2 w-full lg:w-fit rounded-lg mb-3">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                  ${activeTab === tab
                    ? "bg-blue-600 text-white font-medium"
                    : "bg-gray-100 text-black font-medium hover:bg-gray-200"}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="w-full lg:w-auto">
            <button className="px-4 py-2 bg-[#FF4545] flex hover:text-[#FF4545] hover:border-[#FF4545] hover:bg-white border items-center justify-center gap-2 text-white rounded-[8px] underline text-[16px] font-semibold w-full lg:w-auto"
              onClick={handleLogout}
            >
              <PiSignOutBold /> Sign out
            </button>
          </div>
        </div>
      </div>
      <div className="">
        {activeTab === "Account Settings" && (
          <Spin spinning={accountSettingLoader}>

            <div>
              <div className='border-b-[#A3BBFF] pb-10 border-b-[1px]'>
                <h2 className="text-[20px] text-[#000000] font-semibold text-start mb-6">General Details</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                  <div className="flex flex-col">
                    <label className="text-[#313131] text-[16px] font-semibold text-start mb-2">Full Name</label>
                    <input
                      type="text"
                      value={adminProfile?.name}
                      className="px-3 py-2 border border-[#A3BBFF] text-[#4A4A4C] font-medium text-[14px] rounded-lg"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="text-[#313131] text-[16px] font-semibold text-start mb-2">Phone Number</label>
                    <div className="flex items-center justify-between md:justify-start gap-4 md:gap-10">
                      <span className="text-[#878787] text-[14px] font-semibold">{adminProfile?.phone}</span>
                      <button className="text-[14px] text-[#1955EB] underline font-semibold">Change</button>
                    </div>
                  </div>

                  <div className="flex flex-col md:col-span-2">
                    <label className="text-[#313131] text-[16px] font-semibold text-start mb-2">Email Address</label>
                    <div className="flex items-center justify-between md:justify-start gap-4 md:gap-10">
                      <span className="text-[#878787] text-[14px] font-semibold">{adminProfile?.email}</span>
                      <button className="text-[#1955EB] text-[14px] underline font-semibold">Change</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <h2 className="text-start text-[20px] text-[#000000] font-semibold mb-3">Privacy & Security</h2>
                <div className='flex justify-start'>
                  <button className="text-[#1955EB] text-[14px] underline font-semibold" onClick={showChangePasswordModal}>Change Password</button>
                </div>
                <div className='flex justify-start mt-3'>
                  <button className="text-[#FF4545] text-[14px] underline font-semibold">Delete Account</button>
                </div>
              </div>
            </div>
          </Spin>

        )}

        {activeTab === "Notification & Preferences" && (
          <Spin spinning={notificationLoader}>
            <div className="">
              <h2 className="text-[20px] text-[#000000] text-start font-semibold mb-3">Customize Notifications</h2>
              <p className="text-[#313131] text-[16px] text-start font-semibold mb-4">
                Choose the notifications you wish to receive when:
              </p>
              <div className="space-y-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="newSurveyRequest"
                    checked={preferences.newSurveyRequest}
                    onChange={handlePreferenceChange}
                    className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                  />
                  <span className="text-[#313131] text-[16px]">A new survey request is received</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="assignedSurveysNotAccepted"
                    checked={preferences.assignedSurveysNotAccepted}
                    onChange={handlePreferenceChange}
                    className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                  />
                  <span className="text-[#313131] text-[16px]">Assigned surveys are not accepted</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="surveysNotCompleted"
                    checked={preferences.surveysNotCompleted}
                    onChange={handlePreferenceChange}
                    className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                  />
                  <span className="text-[#313131] text-[16px]">Surveys are not completed within the completion period</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="newVerificationRequest"
                    checked={preferences.newVerificationRequest}
                    onChange={handlePreferenceChange}
                    className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                  />
                  <span className="text-[#313131] text-[16px]">New verification request is received</span>
                </label>
              </div>
              <div className="mt-6">
                {/* <button
                  onClick={savePreferences}
                  className="px-4 py-2 bg-[#1955EB] text-white hover:text-[#1955EB] border hover:bg-white hover:border-[#1955EB] rounded-lg text-[16px] font-semibold"
                >
                  Save Preferences
                </button> */}
                <button
                  onClick={savePreferences}
                  disabled={!hasChanges}
                  className={`px-4 py-2 bg-[#1955EB] text-white border rounded-lg text-[16px] font-semibold
              ${hasChanges
                      ? 'hover:text-[#1955EB] hover:bg-white hover:border-[#1955EB]'
                      : 'opacity-50 cursor-not-allowed'}`}
                >
                  Save Preferences
                </button>
              </div>
            </div>
          </Spin>
        )}

        {activeTab === "Admin Permissions" && (
          <Spin spinning={adminPermissionLoader || isLoading}>
            <div className="">
              <h2 className="text-[20px] text-start text-[#000000] font-semibold mb-6">Admin Access</h2>
              <p className="text-[#313131] text-start text-[16px] font-semibold mb-4">
                Provide the email to give access
              </p>
              <div className="flex flex-col md:flex-row gap-4 mb-6">
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="px-3 py-2 border border-[#A3BBFF] text-[#4A4A4C] font-medium text-[14px] rounded-lg w-full max-w-md"
                />
                {/* <button
                disabled={email === "" ? true : false}
                  onClick={handleAddEmail}
                  className="px-4 py-2 bg-[#1955EB] text-white hover:text-[#1955EB] border  hover:bg-white hover:border-[#1955EB] rounded-lg text-[16px] font-semibold"
                >
                  Give Access
                </button> */}
                <button
                  disabled={email === ""}
                  onClick={handleAddEmail}
                  className={`px-4 py-2 bg-[#1955EB] text-white border rounded-lg text-[16px] font-semibold
    ${email !== ""
                      ? 'hover:text-[#1955EB] hover:bg-white hover:border-[#1955EB]'
                      : 'opacity-50 cursor-not-allowed'}`}
                >
                  Give Access
                </button>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {manager?.map((item) => (
                  <div key={item._id} className="flex font-semibold justify-between items-center">
                    <span className="text-[#313131] text-[14px]">{item?.email}</span>
                    <button
                      onClick={() => handleRemoveAccess(item?._id)}
                      className="text-[#FF4545] text-[14px] underline font-semibold"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
        )}
      </div>
      <ChangePasswordModal
        visible={isChangePasswordModalVisible}
        onCancel={() => setIsChangePasswordModalVisible(false)}
        onSuccess={handleChangePasswordSuccess}
      />
    </div>
  );
};

export default SettingsPage;
