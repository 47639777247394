import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../store/authStore";

const LoginProtect = () => {
  const { adminRefToken, isAuthenticated, surveyorRefToken } = useAuthStore();

  if (adminRefToken && isAuthenticated) {
    return <Navigate to="/" replace />;
  } else if(surveyorRefToken && isAuthenticated){
    return <Navigate to="/surveyor" replace />;

  } else {

    return <Outlet />;
  }
};

export default LoginProtect;