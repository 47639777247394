import Loginimg from "../../assets/images/Loginimg.png";
import Loginlogo from "../../assets/images/loginlogo.png";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useState } from "react";

import useAuthStore from "../../store/authStore";
import { login } from "../../api/CommonRequest";
import ForgotPass from "./ForgotPassword/ForgotPass";
import { surveyorLogin } from "../../api/SurveyorApiRequest";
const Login = ({ page }) => {
  console.log(page);

  const [loginView, setLoginView] = useState(true);

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { loginCall } =
    useAuthStore();

  // const handleLogin = async (loginData) => {
  //   try {
  //     setLoader(true);

  //     const { data } = await login(loginData);
  //     if (data?.success) {
  //       setLoader(false);
  //       navigate("/");

  //       loginCall(data);
  //     } else {
  //       setError(data?.message || "An error occurred during login.");
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setError(
  //       error.response?.data.message || "An error occurred during login."
  //     );
  //     setLoader(false);
  //   }
  // };
  const handleLogin = async (loginData) => {
    try {
      setLoader(true);

      const { data } = await (page === "admin" ? login(loginData) : surveyorLogin(loginData));
      console.log(data);
      

      if (data?.success) {
        loginCall(data);
        setLoader(false);
        navigate("/");
      } else {
        setError(data?.message || "An error occurred during login.");
        setLoader(false);
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred during login."
      );
      setLoader(false);
    }
  };

  return (
    <>
      {loginView ?
        <>
          <div className="lg:hidden md:hidden flex justify-center  p-3">
            <img src={Loginlogo} className="h-32 " alt="Architectural background" />
          </div>
          <div className="lg:flex md:flex lg:pb-10 lg:pt-5 lg:px-8 justify-between gap-[60px] lg:h-[90vh] mt-4">
            <div className="hidden sticky top-0  lg:flex lg:w-1/2 items-center justify-center ">
              <img
                src={Loginimg}
                alt="loginimg"
                className="w-full h-auto lg:max-h-[90vh] object-cover rounded-[17px] "
              />
              <div className="absolute right-0 top-0 p-3">
                <img
                  src={Loginlogo}
                  alt="Architectural background"
                  className="max-w-xs"
                />
              </div>
            </div>



            <div className="w-full lg:w-1/2 flex items-center justify-center">
              <div className="w-full lg:w-4/5 md:w-4/5">
                <h1 className="text-[40px] text-start font-bold mb-6">
                  Sign in
                </h1>
                <Form name="sign-in" layout="vertical" onFinish={handleLogin}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input
                      placeholder="Email"
                      className="text-[14px] h-12 w-full font-medium text-[#FF4545]"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Please input your password!" },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      className="text-[14px] h-12 font-medium w-full"
                    />
                  </Form.Item>
                  {error ? <span className="text-red-500 mb-3">{error}</span> : ""}
                  <div className="text-right mb-3 w-full">
                  <div
                  onClick={() => page === "admin" ? navigate("/surveyorLogin") : navigate("/login")}
                  className="text-[14px] cursor-pointer font-medium text-[#1955EB]"
                >
                  Login as {page === "admin" ? "Surveyor" : "Admin"}
                </div>
                    <div
                      onClick={() => {
                        setLoginView(false);
                      }}
                      className="text-[14px] cursor-pointer font-medium text-[#FF4545]"
                    >
                      Forgot Password?
                    </div>
                  </div>

                  <Form.Item>
                    <Button
                      loading={loader}
                      type="primary"
                      htmlType="submit"
                      className="bg-[#1955EB] h-12 w-full font-medium text-white rounded hover:bg-blue-600"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </> :
        <ForgotPass setLoginView={setLoginView} />

      }
    </>
  );
};


export default Login;