import axios from "../api/Axios";
import Swal from "sweetalert2";

export const axiosPrivate = axios.create({});

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const prevRequest = error?.config;
    if (error.response.status === 401 && !prevRequest?.sent) {
      try {
        prevRequest.sent = true;
        const refToken = await localStorage.getItem("userRefToken");
        // const redToken = await localStorage.getItem("surveyorRefToken")
        // console.log(redToken,"999999");
        
        console.log("refToken", refToken);
        const response = await axios.post(
          "/refresh-token",
          { refToken },
          { withCredentials: true }
        );
        if (response.data.success) {
          localStorage.setItem("userRefToken", response.data.refreshToken);
        }

        // if()
       
      } catch (error) {
        console.log(error, "qwerty");
        Swal.fire({
          title: error.response.data.message.name,
          text: error.message + " (Please login again...)",
        }).then(() => {
          localStorage.clear();

          window.location.href = "/";
        });

        return Promise.reject(error);
      }
      return axios(error.config);
    }
    return Promise.reject(error);
  }
);
