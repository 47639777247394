import React, { useEffect, useState } from 'react';
import { Table, Button, Checkbox, Tooltip, Modal, Spin, message } from 'antd';
import { DeleteOutlined, SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { LuArrowUpDown } from 'react-icons/lu';
import { GoHome } from "react-icons/go";
import * as XLSX from 'xlsx';
import { IoMdDownload } from "react-icons/io";
import { Link } from 'react-router-dom';
import { updateUserStatus } from '../../../api/UserRequest';
import {  MdWarning } from 'react-icons/md';
import ErrorLogout from '../../../helpers/ErrorLogout';
import Pagination from '../../common/Pagination';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getAllAssignedSurveys } from '../../../api/SurveyorApiRequest';

const AssignedSurvey = () => {


    const [searchValue, setSearchValue] = useState("")
 
    const [searchTerm, setSearchTerm] = useState(searchValue);
    const [loader, setLoader] = useState(false)

    const [totalPages, setTotalPages] = useState(5);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [trigger, setTrigger] = useState(false);

    const [activeTab, setActiveTab] = useState('assign'); // Update initial state to 'all'
    const [tabFilter, setTabFilter] = useState('assign'); // Update initial state to 'all'
    const [surveys, setSurvey] = useState([]); // Update initial state to '


    const [selectedUsers, setSelectedUsers] = useState([]);
    const tabs = [
        { label: 'Assigned', value: 'assign' },
        { label: 'Pending', value: 'pending' },
        { label: 'Accepted', value: 'accept' },
        { label: 'Rejected', value: 'reject' },
    ];

    const handleSelectUser = (user) => {
        setSelectedUsers(prevSelectedUsers => {
            const isSelected = prevSelectedUsers.some(selectedUser => selectedUser._id === user._id);
            if (isSelected) {
                return prevSelectedUsers.filter(selectedUser => selectedUser._id !== user._id);
            } else {
                return [...prevSelectedUsers, user];
            }
        });
    };
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const handleLimit = (e) => {
        const selectedValue = parseInt(e.target.value); // Convert value to integer if needed
        setLimit(selectedValue); // Update state with the selected value
        setCurrentPage(1);
    }




    useEffect(() => {
        const bidsCall = async () => {
            try {
                setLoader(true);
                const { data } = await getAllAssignedSurveys({
                    //   q: searchTerm,

                    page: currentPage,
                    limit: limit,
                    status: tabFilter
                });
                console.log(data, "21212121");


                if (data?.success) {

                    setSurvey(data?.surveys);
                    setTotalPages(data?.totalPages);
                    setCurrentPage(data?.currentPage);
                    setTotalItems(data?.totalItems);
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);

                console.log(error);
                console.log(error.message);
                if (error?.response?.status === 401) {
                    ErrorLogout(error);
                }
            }
        };
        bidsCall();
    }, [searchTerm, limit, currentPage, tabFilter, trigger]);


    const handleDownloadData = () => {
        if (selectedUsers.length === 0) {
            message.warning('Please select at least one user to download.');
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(selectedUsers.map(user => ({
            Name: user.name,
            Email: user.email,
            'Company Email': user.companyEmail,
            'Created Date': new Date(user.createdDate).toLocaleDateString(),
            'Wallet Balance': user.walletBalance,
            Status: user.status
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

        XLSX.writeFile(workbook, "selected_users.xlsx");

        message.success(`Data for ${selectedUsers.length} user(s) downloaded successfully.`);
    };

    const handleCancelSelection = () => {
        setSelectedUsers([]);
        message.info('Selection cancelled.');
    };


    // ----------------------- DELETE Conform ----------------
    const showUpdateConfirm = (id, action) => {
        Modal.confirm({
            title: 'Confirm Status Update',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to ${action} this user?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                return handleUpdateUserStatus(id, action);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    // ------------- BLOCK WARNING ----------------
    const showBlockConfirm = (id, currentStatus) => {
        let nextAction;
        let actionText;

        switch (currentStatus) {
            case 'active':
                nextAction = 'warn1';
                actionText = 'give the first warning to';
                break;
            case 'warn1':
                nextAction = 'warn2';
                actionText = 'give the second warning to';
                break;
            case 'warn2':
                nextAction = 'deactive';
                actionText = 'deactive';
                break;
            case 'deactive':
                nextAction = 'active';
                actionText = 'active';
                break;
            default:
                nextAction = 'warn1';
                actionText = 'give the first warning to';
        }

        Modal.confirm({
            title: 'Confirm Action',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to ${actionText} this user?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                return handleUpdateUserStatus(id, nextAction);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleUpdateUserStatus = async (id, action) => {
        try {
            const { data } = await updateUserStatus(id, action);

            if (data.success) {

                message.success('User status updated successfully');
                // Refresh the user list or update the local state
            } else {
                message.error('Failed to update user status');
            }
            setTrigger(!trigger)
        } catch (error) {
            console.log('Error updating user status:', error);
            message.error('An error occurred while updating user status');
        }
    };

    const columns = [
        {
            title: 'Select',
            dataIndex: 'select',
            width: 60,
            render: (_, record) => (
                <Checkbox
                    checked={selectedUsers.some(user => user._id === record._id)}
                    onChange={() => handleSelectUser(record)}
                />
            ),
        },

        {
            title: (
                <div className="flex items-center gap-2">
                    Client Name
                    <SearchOutlined className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: 'clientName',
            key: 'clientName',
            render: (text, record) => (
                <div className="flex items-center">
                   
                    <span className="text-[#313131] text-[12px] font-medium">{text}</span>
                </div>
            ),
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Address
                    <SearchOutlined className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: 'address',
            key: 'address',
            render: text => <span className="text-[#313131] text-[12px] font-medium">{text}</span>,
        },
    
        {
            title: (
                <div className="flex items-center gap-2">
                    Contact
                    <SearchOutlined className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: 'contact',
            key: 'contact',
            render: (_, record) => (
                <div className="flex flex-col">
                    <span className="text-[#313131] text-[12px] font-medium flex items-center gap-1">
                  
                        {record?.clientEmail}
                    </span>
                    <span className="text-[#313131] text-[12px] font-medium flex items-center gap-1">
                      
                        {record?.phonePrimary}

                    </span>
                    <span className="text-[#313131] text-[12px] font-medium flex items-center gap-1">
                      
                        {record?.phoneSecondary}

                    </span>
                </div>
            ),
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Created Date
                    <LuArrowUpDown className="text-[#9FACF0]" />
                </div>
            ),
            dataIndex: 'completionDate',
            key: 'completionDate',
            render: (text) => {
                const date = new Date(text);
                return (
                    <span className="text-[#313131] text-[12px] font-medium">
                        {date.toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        })}
                        {' '}
                        {date.toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        })}
                    </span>
                );
            },
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (

                <div className="flex items-center gap-2">
                    <span className={`py-1 rounded-full text-xs ${status === 'deactive' ? 'text-red-600' :
                        'text-green-600'
                        }`}>
                        {status === 'deactive' ? 'Deactivate' : 'Active'}
                    </span>
                    {status === 'warn1' && (
                        <Tooltip title="First Warning">
                            <MdWarning className="text-yellow-600 ml-2" />
                        </Tooltip>
                    )}
                    {status === 'warn2' && (
                        <>
                            <Tooltip title="First Warning">
                                <MdWarning className="text-yellow-600 ml-2" />
                            </Tooltip>
                            <Tooltip title="Second Warning">
                                <MdWarning className="text-orange-600 ml-2" />
                            </Tooltip>
                        </>
                    )}
                </div>
            ),
        },
        {
            title: (
                <div className="flex items-center gap-2">
                    Instructions
                  
                </div>
            ),
            // dataIndex: 'address',
            // key: 'address',
            render: (text, record) => (
                <span 
                    className="text-[#0000FF] text-[12px] font-medium underline cursor-pointer hover:text-[#0000CC]"
                    // onClick={() => handleInstructionsClick(record.address)}
                >
                    Open
                </span>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="flex space-x-2">
                    <Tooltip title="Accept">
                        <Button
                            icon={<CheckCircleOutlined />}
                            className={`text-white ${
                                record.status === 'deactive' 
                                    ? 'bg-green-500 hover:border-green-700' 
                                    : 'bg-[#0445C3] hover:border-[#0445C3]'
                            }`}
                            onClick={() => showBlockConfirm(record._id, record.status)}
                        />
                    </Tooltip>
                    <Tooltip title="Cancel">
                        <Button
                            icon={<CloseCircleOutlined />}
                            className="bg-[#4A4A4C] text-white hover:border-[#4A4A4C]"
                            onClick={() => showUpdateConfirm(record._id, 'cancel')}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button
                            icon={<DeleteOutlined />}
                            className="bg-[#FF4545] text-white hover:border-[#FF4545]"
                            onClick={() => showUpdateConfirm(record._id, 'delete')}
                        />
                    </Tooltip>
                </div>
            ),
        }
    ];

    return (
        <div className="">
            <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
                <div className="mb-4 flex items-center text-sm md:text-base">
                    <Link to="/">    <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
                    <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
                        / Buyers - Individual
                    </span>

                    <div className='flex items-center gap-2 lg:pl-10 lg:mt-0 mt-3'>
                        {selectedUsers.length > 0 && (
                            <>
                                <button
                                    icon={<IoMdDownload className='text-[#1955EB] text-[20px]' />}
                                    onClick={handleDownloadData}
                                    className='text-[#1955EB] underline text-[20px] font-medium'
                                >
                                    Download Selected ({selectedUsers.length})
                                </button>
                                <Button
                                    onClick={handleCancelSelection}
                                    className='text-[#FF4545] underline text-[20px] font-medium'
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className="flex justify-between items-center mb-4 flex-wrap gap-2">
                    <div className="flex flex-wrap gap-2 border border-[#9FACF0] p-2 w-full lg:w-fit rounded-[8px] lg:justify-between">
                        {tabs.map((tab) => (
                            <button
                                key={tab.value}
                                className={`px-4 py-2 mb-2 md:mb-0 md:mr-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                 ${activeTab === tab.value
                                        ? "bg-[#1955EB] text-white font-medium"
                                        : "bg-[#F6F8FF] text-black font-medium hover:bg-[#F6F8FF]"}`}
                                onClick={() => {
                                    setActiveTab(tab.value)
                                    setTabFilter(tab.value)
                                    setCurrentPage(1);

                                }}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <Spin spinning={loader}>

                <div className="pt-[0px] space-y-10 ">
                    <Table
                        columns={columns}
                        dataSource={surveys}
                        pagination={false}
                        className="custom-table-header lg:w-full w-[250px] overflow-auto custom-pagination"
                    />
                    <div >
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            limit={limit}
                            totalItem={totalItems}
                            onPageChange={handlePageChange}
                            handleLimit={handleLimit}
                        />
                    </div>
                </div>
            </Spin>

        </div>
    );
};

export default AssignedSurvey;
