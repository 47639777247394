import React, { useState } from "react";

import Header from "../../components/header";
import SurveyorSidebar from "../../components/surveyordashboard/sidebar/SurveyorSidebar";
import AssignedSurvey from "../../components/surveyordashboard/assignedSurvey/AssignedSurvey";
import SurveySettings from "../../components/surveyordashboard/settings/SurveySettings";
function SurveySettingsPage() {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="flex bg-[#F4F7FF] min-h-screen w-full">
            <div
                className={`fixed top-0 left-0 h-full z-10 transition-all duration-300 ${isCollapsed ? "lg:w-20 md:w-24 w-16" : "lg:w-64 md:w-48 w-fit"
                    } bg-[#F4F7FF]`}
            >
                <SurveyorSidebar
                    isCollapsed={isCollapsed}
                    onToggleCollapse={handleToggleCollapse}
                />
            </div>

            <div
                className={`flex-grow transition-all duration-300 ml-0 ${isCollapsed ? "lg:pl-20 md:pl-24 pl-16" : "lg:pl-72 md:pl-48 pl-16"
                    } bg-[#D2DEFF] lg:min-h-screen flex flex-col items-center justify-center p-3 lg:pr-6`}
            >
                <div className="w-full lg:h-[90vh]  p-4 lg:p-6 md:p-5 bg-white rounded-lg shadow-lg border border-[#9FACF0] flex flex-col">
                    <Header className="sticky top-0 lg:z-20" />
                    <div className="flex-grow overflow-y-auto scrollbar-hide">
                        <SurveySettings />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveySettingsPage;
