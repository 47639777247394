import { Outlet, Navigate } from "react-router-dom";
import useAuthStore from "../store/authStore";

const SurveyorRouteProtect = () => {
  const { surveyorRefToken, isAuthenticated } = useAuthStore();

  
  return surveyorRefToken && isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={"/login"} />
  );
};
export default SurveyorRouteProtect;
