import { Modal, Select, TimePicker, Upload } from 'antd';
import React, { useState } from 'react';
import { GoHome } from "react-icons/go";
import { PiSignOutBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useAuthStore from '../../../store/authStore';

const { Option } = Select;
const { confirm } = Modal;

const SurveySettings = () => {
  const { logout, surveyorRefToken } = useAuthStore();

    const tabs = ["Account Settings", "Notification & Preferences"];
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [email, setEmail] = useState("");
    const [adminEmails, setAdminEmails] = useState([
        "akvindevs@gmail.com",
        "akvindevs@gmail.com",
        "akvindevs@gmail.com",
        "akvindevs@gmail.com",
        "akvindevs@gmail.com",
        "akvindevs@gmail.com",
    ]);
    const handleAddEmail = () => {
        if (email && !adminEmails.includes(email)) {
            setAdminEmails([...adminEmails, email]);
            setEmail("");
        }
    };
    const handleRemoveEmail = (emailToRemove) => {
        setAdminEmails(adminEmails.filter((email) => email !== emailToRemove));
    };

      // ------------LOGOUT FUNCTION-----------
  const handleLogout = () => {
    confirm({
      title: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will be redirected to the login page.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        logout(surveyorRefToken, "surveyor");
      },
      onCancel() {
        console.log('Logout cancelled');
      },
    });
  };

    // ----------------------- IMAGE ------------------

    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    return (
        <div className="">
            <div className="lg:sticky top-0 pb-3 lg:z-10 bg-white">
                <div className="mb-4 flex items-center text-sm md:text-base flex-wrap">
                    <Link to="/">  <GoHome className="text-[#C7C7C7] text-[20px] md:text-[24px]" /></Link>
                    <span className="ml-2 text-[#313131] text-base md:text-lg font-medium">
                        / Settings
                    </span>
                </div>
                <div className="flex flex-wrap justify-between items-center mb-4 gap-2">
                    <div className="flex flex-wrap gap-2 border border-gray-300 p-2 w-full lg:w-fit rounded-lg mb-3">
                        {tabs.map((tab) => (
                            <button
                                key={tab}
                                className={`px-4 py-2 rounded-md text-sm md:text-base transition-colors duration-150 ease-in-out
                  ${activeTab === tab
                                        ? "bg-blue-600 text-white font-medium"
                                        : "bg-gray-100 text-black font-medium hover:bg-gray-200"}`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <div className="w-full lg:w-auto">
                        <button
                        onClick={handleLogout}
                        className="px-4 py-2 bg-[#FF4545] flex hover:text-[#FF4545] hover:border-[#FF4545] hover:bg-white border items-center justify-center gap-2 text-white rounded-[8px] underline text-[16px] font-semibold w-full lg:w-auto">
                            <PiSignOutBold /> Sign out
                        </button>
                    </div>
                </div>
            </div>
            <div className="">
                {activeTab === "Account Settings" && (
                    <div>


                        <div className='border-b-[#A3BBFF] pb-10 border-b-[1px]'>
                            <h2 className="text-[20px] text-[#000000] font-semibold text-start mb-6">General Details</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Left Column */}
                                <div>
                                    <Upload
                                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onChange}
                                        onPreview={onPreview}
                                    >
                                        {fileList.length < 5 && '+ Upload'}
                                    </Upload>

                                    <div className="flex flex-col mt-2">
                                        <label className="text-[#313131] text-[16px] font-semibold text-start mb-2">Full Name</label>
                                        <input
                                            type="text"
                                            value="Aquin Devis"
                                            className="px-3 py-2 border border-[#A3BBFF] text-[#4A4A4C] font-medium text-[14px] rounded-lg"
                                        />
                                    </div>
                                </div>

                                {/* Right Column */}
                                <div className="flex flex-col">
                                    <label className="text-[#313131] text-[16px] font-semibold text-start mb-2">Email Address</label>
                                    <div className="flex items-center justify-between md:justify-start gap-4 md:gap-10">
                                        <span className="text-[#878787] text-[14px] font-semibold">akvindevs@gmail.com</span>
                                        <button className="text-[#1955EB] text-[14px] underline font-semibold">Change</button>
                                    </div>

                                    <label className="text-[#313131] text-[16px] font-semibold text-start mb-2 mt-4">Phone Number</label>
                                    <div className="flex items-center justify-between md:justify-start gap-4 md:gap-10">
                                        <span className="text-[#878787] text-[14px] font-semibold">8156900945</span>
                                        <button className="text-[#1955EB] text-[14px] underline font-semibold">Change</button>
                                    </div>

                                    <label className="text-[#313131] text-[16px] font-semibold text-start mb-2 mt-4">Address</label>
                                    <div className="flex items-center justify-between md:justify-start gap-4 md:gap-10">
                                        <div className='flex flex-col'>
                                            {adminEmails.map((email, index) => (
                                                <span key={index} className="text-[#878787] text-[14px] font-semibold">
                                                    {email}
                                                </span>
                                            ))}
                                        </div>
                                        <button className="text-[#1955EB] text-[14px] underline font-semibold">Change</button>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="border-b-[#A3BBFF] pb-10 border-b-[1px] pt-3">
                            <h2 className="text-start text-[20px] text-[#000000] font-semibold mb-3">Survey Preference</h2>
                            <div className='grid grid-cols-2 md:grid-cols-2 gap-6'>
                                <div>
                                    <label className="text-[#313131] text-[12px] font-semibold text-start mb-2">category</label>

                                    <Select
                                        mode="multiple"
                                        className="w-full text-[#4A4A4C] text-[14px] rounded placeholder-[#4A4A4C]"
                                        placeholder="Select an option"
                                    // defaultValue={locations}
                                    >
                                        <Option value="mangalore">Mangalore</Option>
                                        <Option value="bangalore">Bangalore</Option>
                                        <Option value="goa">Goa</Option>
                                    </Select>
                                </div>
                                <div>
                                    <label className="text-[#313131] text-[12px] font-semibold text-start mb-2">Survey Location</label>

                                    <Select
                                        mode="multiple"
                                        className="w-full text-[#4A4A4C] text-[14px] rounded placeholder-[#4A4A4C]"
                                        placeholder="Select an option"
                                    // defaultValue={locations}
                                    >
                                        <Option value="mangalore">Mangalore</Option>
                                        <Option value="bangalore">Bangalore</Option>
                                        <Option value="goa">Goa</Option>
                                    </Select>
                                </div>

                            </div>

                        </div>

                        <div className="border-b-[#A3BBFF] pb-10 border-b-[1px] pt-3">
                            <h2 className="text-start text-[20px] text-[#000000] font-semibold mb-3">Privacy & Security</h2>
                            <div className='flex justify-start'>
                                <button className="text-[#1955EB] text-[14px] underline font-semibold">Change Password</button>
                            </div>
                            <div className='flex justify-start mt-3'>
                                <button className="text-[#FF4545] text-[14px] underline font-semibold">Delete Account</button>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === "Notification & Preferences" && (
                    <  div >

                        <div className="border-b-[#A3BBFF] pb-10 border-b-[1px] pt-3">
                            <h2 className="text-[20px] text-[#000000] text-start font-semibold mb-3">Customize Notifications</h2>
                            <p className="text-[#313131] text-[16px] text-start font-semibold mb-4">
                                Choose the notifications you wish to receive when:
                            </p>
                            <div className="space-y-4">
                                <label className="flex items-center space-x-2">
                                    <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" />
                                    <span className="text-[#313131] text-[16px]">A new survey is Assigned</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" />
                                    <span className="text-[#313131] text-[16px]">Submitted survay is rejected</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" />
                                    <span className="text-[#313131] text-[16px]">Assigned surveys is closed to completed  period</span>
                                </label>

                            </div>
                        </div>
                        <div className="m-2 mb-4">
                            <h2 className="text-[20px] text-[#000000] text-start font-semibold mb-3">Customize Availability</h2>
                            <p className="text-[#313131] text-[16px] text-start font-normal mb-4">
                                You'll only be shown as available in the hours that you choose. Outside of those times, your status will be unavailable.
                            </p>
                            <div className="grid lg:grid-cols-3  md:grid-cols-3 sm:grid-cols-1 gap-3">
                                <div className="flex flex-col  space-x-4">
                                    <span className="w-24 text-[#313131] text-[14px] font-medium">Frequency</span>
                                    <Select defaultValue="everyday" style={{ width: 200 }}>
                                        <Option value="everyday">Every Day</Option>
                                        <Option value="weekdays">Weekdays</Option>
                                        <Option value="weekends">Weekends</Option>
                                        <Option value="custom">Custom</Option>
                                    </Select>
                                </div>
                                <div className="flex flex-col   space-x-4">
                                    <span className="w-24 text-[#313131] text-[14px] font-medium">From</span>
                                    <TimePicker
                                        use12Hours
                                        format="h:mm A"
                                        className="w-[200px]"
                                        suffixIcon={<ClockCircleOutlined />}
                                        placeholder="Select time"
                                    />
                                </div>
                                <div className="flex flex-col  space-x-4">
                                    <span className="w-24 text-[#313131] text-[14px] font-medium">To</span>
                                    <TimePicker
                                        use12Hours
                                        format="h:mm A"
                                        className="w-[200px]"
                                        suffixIcon={<ClockCircleOutlined />}
                                        placeholder="Select time"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* {activeTab === "Admin Permissions" && (
                    <div className="">
                        <h2 className="text-[20px] text-start text-[#000000] font-semibold mb-6">Admin Access</h2>
                        <p className="text-[#313131] text-start text-[16px] font-semibold mb-4">
                            Provide the email to give access
                        </p>
                        <div className="flex flex-col md:flex-row gap-4 mb-6">
                            <input
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="px-3 py-2 border border-[#A3BBFF] text-[#4A4A4C] font-medium text-[14px] rounded-lg w-full max-w-md"
                            />
                            <button
                                onClick={handleAddEmail}
                                className="px-4 py-2 bg-[#1955EB] text-white hover:text-[#1955EB] border  hover:bg-white hover:border-[#1955EB] rounded-lg text-[16px] font-semibold"
                            >
                                Give Access
                            </button>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            {adminEmails.map((email, index) => (
                                <div key={index} className="flex font-semibold justify-between items-center">
                                    <span className="text-[#313131] text-[14px]">{email}</span>
                                    <button
                                        onClick={() => handleRemoveEmail(email)}
                                        className="text-[#FF4545] text-[14px] underline font-semibold"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    );
};
export default SurveySettings;